<script>
import axios from "axios";

import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import { required } from "vuelidate/lib/validators";
let user = JSON.parse(localStorage.getItem("user"));

/**
 * Change component
 */
export default {
  page: {
    title: "Thêm tài khoản nhân viên",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Sửa mật khẩu tài khoản",
      items: [
        {
          text: "Trang Chủ",
          href: "/",
        },
        {
          text: "Sửa mật khẩu tài khoản",
          active: true,
        },
      ],

      user: { 
          old_password: null,
          new_password: null,
          confirm_password:null
      },
      submitted: false,
      regError: null,
      tryingToEdit: false,
      isEditError: false,
      editSuccess: false,
      scriptSuccess: null,
    };
  },
  validations: {
    user: {
      old_password: {
        required,
      },
      new_password: {
        required,
      },
      confirm_password: {
        required,
      },
    },
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    // Try toedit the user in with the email, username
    // and password they provided.
    tryToEditIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        axios({
          url: process.env.VUE_APP_SERVER_URL + "/api/user/changer-password",
          method: "post",
          headers: {
            authorization: user.token,
          },

          data: this.user,
        })
          .then(({ data }) => {
            this.tryingToEdit = false;
            this.isEditError = false;
            this.editSuccess = true;

            if (data && data.email && data._id == user._id) {
              data.token = user.token;
              localStorage.setItem("user", JSON.stringify(data));
            }
            this.$toasted.success("Cập nhập tài khoản thành công");
            return data;
          })
          .catch((error) => {
              console.log(error)
            this.tryingToEdit = false;
            this.regError =
              error && error.response && error.response.data
                ? error.response.data
                : "";
            this.$toasted.error(error.response.data);
            this.isEditError = true;

          });
      }
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" />
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-8 col-xl-8">
        <div class="card overflow-hidden">
          <div class="bg-soft bg-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-4">
                  <h5 class="text-primary">Sửa mật khẩu tài khoản</h5>
                  <p>Bạn cần nhập đầy đủ thông tin theo biểu mẫu.</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img
                  src="@/assets/images/profile-img.png"
                  alt
                  class="img-fluid"
                />
              </div>
            </div>
          </div>

          <div class="card-body pt-0">
            <div>
              <router-link to="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img
                      src="@/assets/images/logo.svg"
                      alt
                      class="rounded-circle"
                      height="34"
                    />
                  </span>
                </div>
              </router-link>
            </div>

            <b-alert
              v-if="editSuccess"
              v-model="editSuccess"
              class="mt-3"
              variant="success"
              dismissible
              >Sửa thông tin thành công
            </b-alert>

            <b-alert
              v-model="isEditError"
              class="mt-3"
              variant="danger"
              dismissible
              >{{ regError }}</b-alert
            >

            <b-form class="p-2" @submit.prevent="tryToEditIn">
              <b-form-group
                class="mb-3"
                id="old-password"
                label="Mật khẩu cũ"
                label-for="username"
              >
                <b-form-input

                  v-model="user.old_password"
                  type="password"
                  placeholder="Nhập mật khẩu hiện tại của bạn"
                  :class="{
                    'is-invalid': submitted && $v.user.old_password.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.user.old_password.required"
                  class="invalid-feedback"
                >
                  Mật khẩu cũ là cần thiết
                </div>
              </b-form-group>

              <b-form-group
                class="mb-3"
                id="new-password"
                label="Mật khẩu mới"
                label-for="new password"
              >
                <b-form-input
                  id="new pass"
                  v-model="user.new_password"
                  type="password"

                  placeholder="Nhập mật khẩu mới của bạn lớn hơn 6 kí tự"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                class="mb-3"
                id="account-confirm_password"
                label="Xác nhận mật khẩu"
                label-for="confirm_password"
              >
                <b-form-input
                  id="confirm_password"
                  v-model="user.confirm_password"
                  type="text"
                  placeholder="Điền lại mật khẩu mới lớn hơn 6 kí tự"
                  :class="{
                    'is-invalid': submitted && $v.user.confirm_password.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.user.confirm_password.required"
                  class="invalid-feedback"
                >
                    Mật khẩu xác nhận là cần thiết
                </div>
              </b-form-group>

              <div class="mt-4 d-grid">
                <b-button type="submit" variant="primary" class="btn-block"
                  >Cập nhập mật khẩu tài khoản</b-button
                >
              </div>
            </b-form>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>